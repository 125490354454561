var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popularGoodsAdd" },
    [
      _vm.dialogaddVisible == false
        ? _c("div", { staticClass: "actions_part clearfix" }, [
            _c("div", { staticClass: "actions_wrap fl" }, [
              _c(
                "div",
                { staticClass: "display_ib mr10 mb10" },
                [
                  _c("span", { staticClass: "red mr5" }, [_vm._v("*")]),
                  _c("span", { staticClass: "tag" }, [_vm._v("供应商：")]),
                  _c(
                    "el-select",
                    {
                      staticClass: "select_single w200 mr10",
                      attrs: {
                        title: _vm.supplierCode,
                        filterable: "",
                        placeholder: "请选择"
                      },
                      on: { change: _vm.getselectHandle },
                      model: {
                        value: _vm.supplierCode,
                        callback: function($$v) {
                          _vm.supplierCode = $$v
                        },
                        expression: "supplierCode"
                      }
                    },
                    [
                      _c("el-option", {
                        key: "",
                        attrs: { label: "请选择", value: "" }
                      }),
                      _vm._l(_vm.supplierList, function(item) {
                        return _c("el-option", {
                          key: item.supplierCode,
                          attrs: {
                            label: item.supplierCode,
                            value: item.supplierCode
                          }
                        })
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "down fr" },
              [
                _c("el-button", {
                  attrs: {
                    size: "small",
                    type: "primary",
                    icon: "el-icon-plus",
                    circle: "",
                    title: "添加"
                  },
                  on: { click: _vm.addChildHandle }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _c("upload-excel-component", {
        staticClass: "mb20",
        attrs: {
          "on-success": _vm.importExcelSuccess,
          "before-upload": _vm.importExcelBeforeUpload
        }
      }),
      _c(
        "div",
        [
          _c("editTable", {
            attrs: {
              tableList: _vm.form.factoryGoodsList,
              tableLeaderFieldsList: _vm.leaderFieldsList,
              tableTailFieldsList: _vm.tailFieldsList,
              tableFieldsList: _vm.editFieldsList
            },
            on: { deleteHandle: _vm.delChildHandle }
          }),
          _c(
            "div",
            { staticClass: "edit_btn_wrap mt10 fr" },
            [
              _c("el-button", { on: { click: _vm.cancelVisibleclick } }, [
                _vm._v("取消")
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.addVisibleclick }
                },
                [_vm._v("提交")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }