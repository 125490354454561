var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "factoryGoods nav_tab_wrap" }, [
    _c(
      "div",
      { staticClass: "materials" },
      [
        _c(
          "el-tabs",
          {
            staticClass: "nav_wrap",
            attrs: { type: "border-card" },
            on: { "tab-click": _vm.handleClick },
            model: {
              value: _vm.activeName,
              callback: function($$v) {
                _vm.activeName = $$v
              },
              expression: "activeName"
            }
          },
          [
            _c(
              "el-tab-pane",
              { attrs: { label: "工厂快货", name: "getpopularGoods" } },
              [_vm.isGoods ? _c("popularGoods") : _vm._e()],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }