var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container popularGoods" },
    [
      _vm.dialogaddVisible == false
        ? _c("div", { staticClass: "actions_part clearfix" }, [
            _c("div", { staticClass: "actions_wrap" }, [
              _c(
                "div",
                { staticClass: "display_ib mr10 mb10" },
                [
                  _c("span", { staticClass: "tag" }, [_vm._v("物料编号：")]),
                  _c("el-input", {
                    staticClass: "input_single w200 mr10",
                    attrs: { placeholder: "请输入" },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.searchHandle($event)
                      }
                    },
                    model: {
                      value: _vm.materialCode,
                      callback: function($$v) {
                        _vm.materialCode =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "materialCode"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "display_ib mr10 mb10" },
                [
                  _c("span", { staticClass: "tag" }, [_vm._v("供应商：")]),
                  _c(
                    "el-select",
                    {
                      staticClass: "select_single w200 mr10",
                      attrs: { filterable: "", placeholder: "请选择" },
                      on: { change: _vm.getPopularGoodsList },
                      model: {
                        value: _vm.supplierCode,
                        callback: function($$v) {
                          _vm.supplierCode = $$v
                        },
                        expression: "supplierCode"
                      }
                    },
                    [
                      _c("el-option", {
                        key: "",
                        attrs: { label: "全部", value: "" }
                      }),
                      _vm._l(_vm.supplierList, function(item) {
                        return _c("el-option", {
                          key: item.supplierId,
                          attrs: {
                            label: item.supplierCode,
                            value: item.supplierId
                          }
                        })
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "actions_btn_wrap down t_right" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.excelDownload }
                  },
                  [_vm._v("导出Excel")]
                ),
                _c("el-button", {
                  attrs: {
                    size: "small",
                    type: "primary",
                    icon: "el-icon-search",
                    circle: "",
                    title: "搜索"
                  },
                  on: { click: _vm.searchHandle }
                }),
                _c("el-button", {
                  attrs: {
                    size: "small",
                    type: "primary",
                    icon: "el-icon-plus",
                    circle: "",
                    title: "添加"
                  },
                  on: { click: _vm.addHandle }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm.dialogaddVisible == false
        ? _c("div", { attrs: { id: "" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.tableLoading,
                    expression: "tableLoading"
                  }
                ],
                staticClass: "table_part clearfix",
                staticStyle: { "min-height": "536px" }
              },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.listLoading,
                        expression: "listLoading"
                      }
                    ],
                    ref: "multipleTable",
                    staticClass: "table_list",
                    attrs: {
                      data: _vm.requestList,
                      "row-key": "supplierCode",
                      "expand-row-keys": _vm.expands,
                      border: "",
                      fit: "",
                      stripe: ""
                    },
                    on: {
                      "row-click": _vm.rowClick,
                      "expand-change": _vm.rowClick
                    }
                  },
                  [
                    _c("el-table-column", {
                      attrs: { type: "expand" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "actions_wrap mb20 clearfix" },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "fr",
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function($event) {
                                            return _vm.childExcelDownload(
                                              scope.row
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("导出Excel")]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-table",
                                  {
                                    directives: [
                                      {
                                        name: "loading",
                                        rawName: "v-loading",
                                        value: _vm.childListLoading,
                                        expression: "childListLoading"
                                      }
                                    ],
                                    ref: "childMultipleTable_" + scope.row.id,
                                    staticClass: "table_list",
                                    attrs: {
                                      data: scope.row.factoryGoodsList,
                                      border: "",
                                      fit: ""
                                    }
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: { fixed: "", label: "行号" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var row = ref.row
                                              return [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(row.sn) +
                                                    "\n                "
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        fixed: "",
                                        label: "物料编号",
                                        "min-width": "180",
                                        "class-name": "marpno_wuliao",
                                        "show-overflow-tooltip": true
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var row = ref.row
                                              return [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(row.materialCode) +
                                                    "\n                "
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    }),
                                    _c("el-table-column", {
                                      attrs: { label: "快货类型" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var row = ref.row
                                              return [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(row.goodsTypeName) +
                                                    "\n                "
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    }),
                                    _c("el-table-column", {
                                      attrs: { label: "现货" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var row = ref.row
                                              return [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      row.spotGoodsQuantity
                                                    ) +
                                                    "\n                "
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    }),
                                    _c("el-table-column", {
                                      attrs: { label: "15天快货" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var row = ref.row
                                              return [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      row.halfMonthQuantity
                                                    ) +
                                                    "\n                "
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    }),
                                    _c("el-table-column", {
                                      attrs: { label: "30-45天快货" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var row = ref.row
                                              return [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      row.afterMonthQuantity
                                                    ) +
                                                    "\n                "
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        642559686
                      )
                    }),
                    _c("el-table-column", {
                      attrs: { label: "序号" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(row.idIndex) +
                                    "\n          "
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1909258668
                      )
                    }),
                    _c("el-table-column", {
                      attrs: { label: "供应商" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(row.supplierCode) +
                                    "\n          "
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2078391302
                      )
                    }),
                    _c("el-table-column", {
                      attrs: { label: "更新时间" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var row = ref.row
                              return [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(row.updateTime) +
                                    "\n          "
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        952737083
                      )
                    }),
                    _c("el-table-column", {
                      attrs: { label: "操作" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var row = ref.row
                              var $index = ref.$index
                              return [
                                row.actions.hasEdit || row.actions.hasDelete
                                  ? _c(
                                      "div",
                                      [
                                        _c("el-button", {
                                          staticClass: "el-icon-edit",
                                          attrs: {
                                            type: "primary",
                                            circle: "",
                                            size: "mini",
                                            title: "编辑"
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.factoryupd($index, row)
                                            }
                                          }
                                        }),
                                        _c("el-button", {
                                          staticClass:
                                            "el-icon-delete\n              ",
                                          attrs: {
                                            type: "danger",
                                            circle: "",
                                            size: "mini",
                                            title: "删除"
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.factorydel($index, row)
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        761292320
                      )
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "pagination_wrap fr mt10" },
              [
                _c("el-pagination", {
                  attrs: {
                    "current-page": _vm.currentPage,
                    "page-sizes": [10, 50],
                    "page-size": _vm.pageSize,
                    layout: "sizes, total, prev, pager, next",
                    total: _vm.total
                  },
                  on: {
                    "size-change": _vm.handleSizeChange,
                    "current-change": _vm.handleCurrentChange,
                    "update:currentPage": function($event) {
                      _vm.currentPage = $event
                    },
                    "update:current-page": function($event) {
                      _vm.currentPage = $event
                    }
                  }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _c(
        "div",
        [
          _vm.dialogaddVisible
            ? _c("popularGoodsAdd", {
                attrs: {
                  updtag: _vm.updtag,
                  supplierList: _vm.supplierList,
                  goodsTypeList: _vm.goodsTypeList,
                  showId: _vm.showId
                },
                on: { close: _vm.close }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "提示",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            visible: _vm.dialogdelVisible,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogdelVisible = $event
            }
          }
        },
        [
          _c("span", [_vm._v("确定删除该条数据?")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogdelVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.delVisibleclick()
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }